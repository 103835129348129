import React from "react";
import { Navigate,useLocation  } from "react-router-dom";
import Layout from "../components/layout/Layout";
import { getSession } from "../services/AuthContext";
function PrivateRoute({ children, user, ...rest }) {
  //  const auth = user.logged;
 
  const  user_sess  = getSession();
  console.log("UUUSSSERRR",user_sess);

  const auth = user_sess?true:false;
  
  return auth ? (<Layout>{children}</Layout>) : <Navigate to="/" />;
}

export default PrivateRoute;
