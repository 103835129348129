import { useEffect, useState } from "react";
import categoriesServices from "../../services/categories";
import { Oval } from "react-loader-spinner";
import { useParams, useNavigate } from "react-router-dom";
import {

  useNameSubCategory,

  usePdfSource,
} from "../../store/index";
import { Document, Page } from "react-pdf";

import BuscadorInterno from "../buscar/buscador-interno";

import MostrarPDF from "../buscar/mostrarpdf";

export default function Decretos() {
  const [loading, setLoading] = useState(false);
  const [subCategories, setSubCategories] = useState([]);

  const [query, setQuery] = useState('');
  const [subCategoriesFilters, setSubCategoriesFilters] = useState([]);


  const [pdfMode,setPdfMode] = useState(false);
  const [currentPDFLink,setCurrentPDFLink] = useState(null);
  const [itemPDF,setItemPDF] = useState(null);


  const handledQuery = ({ target }) => {
    const value = target.value;
    setQuery(value);
  }


  useEffect(() => {
    if (query === '') {
      setSubCategoriesFilters(subCategories);
    }
    else {
      const newData = subCategories.filter(decreto => {
        return decreto.name.toLowerCase().includes(query.toLowerCase())
      })
      setSubCategoriesFilters(newData)
    }


  }, [query, subCategories])
  //const [pdfURL, setPdfURL] = useState(null);
  //const [numPages, setNumPages] = useState(null);
  //const [pageNumber, setPageNumber] = useState(1);
  //const categoryName = useNameCategory((state) => state.categoryName);
  //const subCategory = useStoreSubCategory((state) => state.subCategory);
  const { idCategoria } = useParams();

  const subCategoryName = useNameSubCategory((state) => state.subCategoryName);
  const setPdfSource = usePdfSource((state) => state.setPdfSource);
  const navigate = useNavigate();

  useEffect(() => {
    const getSubCategories = async () => {
      setLoading(true);
      const response = await categoriesServices.getPdfCategoriesByParent(
        idCategoria
      );
      setSubCategories(response.data);
      setLoading(false);
    };
    getSubCategories();
  }, [idCategoria]);

  /**cardar pdf-- no funcionó la libreria */
  /*const handleLoadPDF = (url) => {
    setPdfURL(url);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };*/

  function visorPDF(item){
    let _link = (item.source_path)
      setCurrentPDFLink(_link);
      setItemPDF(item);
      //console.log(_link);
      setPdfMode(true);
  }

  return (
    <div className="bg-full2 h100vh custom-houdini">
      <div className="container-custom">
        <div className="row">
          <div className="col-10">
            <p className="container-back" onClick={() => { navigate(-1); }}>
              <i className="fa-sharp fa-solid fa-chevron-left"></i>
              <h3 className="name-custom">{subCategoryName}</h3>
            </p>
           
          </div>
          <div className="col-2">
              <BuscadorInterno idCategoria={idCategoria} />
            </div>
          </div>
          <form className="search-custom" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Buscar"
              aria-label="Buscar"
              onChange={e => handledQuery(e)}
            />
          </form>
          <div className="container-cards1">
            {loading ? (
              <div className="col-12 mt-25 ">
                <Oval
                  type="ThreeDots"
                  color="#00BFFF"
                  height={80}
                  width={80}
                  strokeWidth={3}

                />
              </div>
            ) : (
              subCategoriesFilters.map((subcategorias, index) => {
                const [name, description] = subcategorias.name.split("- ");
                return (
                  <div className="card-container" key={index}>
                    <div className="card">
                      <div className="card-body2">
                        <p
                          className="txt-custom04"
                          onClick={() => {
                           /*  setPdfSource(subcategorias.source);
                            navigate(`/private/decretospdf/${subcategorias.id}`); */
                            visorPDF(subcategorias)
                          }}
                        >
                          {name}
                        </p>
                        <p className="text-description">{description}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}

          </div>
        </div>
        {
        pdfMode && (
          <MostrarPDF pdfLink={currentPDFLink} visorMode={true} close={()=>{setPdfMode(false)}} item={itemPDF} />
        )
      }
      </div>
      );
}
