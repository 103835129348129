import { createContext } from 'react';

export const setSession = (data)=>{
	if(data){
		console.log("SET SESS")
		console.log(data)
		localStorage.setItem("BLUEUSER",JSON.stringify(data));
	}else{
		localStorage.removeItem("BLUEUSER");
	}
	
}

export const getSession  =()=>{
	let userData = localStorage.getItem("BLUEUSER") && localStorage.getItem("BLUEUSER")!='undefined'? JSON.parse(localStorage.getItem("BLUEUSER")):null;
	if(userData && userData.token){
		return userData;
	}else{
		return null;
	}
}

export const AuthContext = createContext(getSession);


