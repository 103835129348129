import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import categoriesServices from "../../services/categories";
import { useStoreDirectorio } from "../../store";
import { Oval } from "react-loader-spinner";
import phone from "./assets/img/phone.png";
import { getSession } from "../../services/AuthContext";

import "./assets/directorio.css";

const Directorio = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const directorio = useStoreDirectorio((state) => state.directorio);
  const setDirectorio = useStoreDirectorio((state) => state.setDirectorio);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCategory, setFilteredCategory] = useState([]);

  const  user_sess  = getSession();
  
  useEffect(()=>{
    if(!user_sess.isPremium){
      navigate("/private/User");
    }
  },[])

  useEffect(() => {
    const getDirectorio = async () => {
      setLoading(true);
      const response = await categoriesServices.getDirectorio();
      setDirectorio(response.data);
      setLoading(false);
    };
    getDirectorio();
  }, []);

  useEffect(() => {
    const filterCategory = () => {
      const filtered = directorio.filter((directorio) =>
        directorio.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredCategory(filtered);
    };
    filterCategory();
  }, [directorio, searchQuery]);

  const handleCardClick = (index) => {
    const updatedCategory = [...directorio];
    updatedCategory[index].expanded = !updatedCategory[index].expanded;
    setDirectorio(updatedCategory);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <img src='https://raw.githubusercontent.com/KevinArce/Extras/main/img/fondo.svg' alt="logo" className="fondo-custom" />

      <div className="contenido-full">
        <div className="container-custom">
          <p className="container-name">
            <h3 className="title">Directorio</h3>
          </p>

          <div className="container-buscar">
            <input
              className="input-custom-buscar"
              type="text"
              placeholder="Buscar..."
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>

          <div className="container-section-cards">
            <div className="row">
              {loading ? (
                <div className="col-12 mt-25">
                  <Oval
                    type="ThreeDots"
                    color="#00BFFF"
                    height={80}
                    width={80}
                    strokeWidth={3}
                  />
                </div>
              ) : (
                filteredCategory.map((directorio, index) => (
                  <div
                    className="card-container-directory"
                    key={index}
                    onClick={() => handleCardClick(index)}
                  >
                    <div className="card-item">
                      <div className="section">
                        <div className="colum1">
                          <img src={phone} alt="phone" className="" />
                        </div>
                        <div className="colum2">
                          <p className="title-directory">{directorio.name}</p>
                          <p className="sub-title-directory">
                            {directorio.category}
                          </p>
                        </div>
                      </div>

                      {directorio.expanded && (
                        <>
                          <div className="section">
                            <div className="colum1"></div>
                            <div className="colum2">
                              {directorio.phone1 && (
                                <p className="txt-phone">
                                  Teléfono: <a className="link-phone" href={`tel:${directorio.phone1}`}>{directorio.phone1}</a>
                                </p>
                              )}
                              {directorio.phone2 && (
                                <p className="txt-phone">
                                  Teléfono: <a className="link-phone" href={`tel:${directorio.phone2}`}>{directorio.phone2}</a>
                                </p>
                              )}
                              {directorio.phone3 && (
                                <p className="txt-phone">
                                  Teléfono: <a className="link-phone" href={`tel:${directorio.phone3}`}>{directorio.phone3}</a>
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default Directorio;