import React, { useState } from "react";
import fondo from "../../globalassets/img/fondo.svg";
import { useNavigate } from "react-router-dom";
 
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import servicesApi from "../../services/services";
import Loading from "../layout/Loading";
import { setSession } from "../../services/AuthContext";

export default function SuperAdminRegistro(props) {
  const [passwordType, setPasswordType] = useState("password");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [terminos, setTerminos] = useState(false);
  const [wait, setWait] = useState(false);

  const navigate = useNavigate();

  function register(e) {
    e.preventDefault();

    setWait(true);
    const params = { ...formData, pwdkey: "M4rd0queo1",fromAdmin:1 };

    servicesApi.useService(true);
    servicesApi.post("registrar", params).then(
      (response) => {
        console.log(response);
        setWait(false);
        if (response.data.error == "1") {
          toast.error(response.data.mensaje);
        } else {
          setFormData({});
          if(props.callback){
			props.callback(response.data);
		  }
        }
      },
      () => {
        setWait(false);
        toast.error("Error, intente más tarde");
      }
    );
  }

  const handlePasswordChange = (evnt) => {
    setFormData({ ...formData, password: evnt.target.value });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <>
    
      <div className="">
         
        <form action="" onSubmit={register}>
          <div className="container-bienvenida">
            <div className="form-register" style={{height:'300px'}}>
              <label className="title-txt-login">Registrar Usuario</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre Completo"
                  required
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                  value={formData.name}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Correo electrónico"
                  required
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                  value={formData.email}
                />
              </div>
              <div className="form-group mb-4">
                <input
                  type={passwordType}
                  className="form-control"
                  placeholder="Contraseña"
                  required
                  onChange={handlePasswordChange}
                />

                <button
                  className="btn-show-pass"
                  type="button"
                  onClick={togglePassword}
                >
                  {passwordType === "password" ? (
                    <i className="fa-light fa-eye"></i>
                  ) : (
                    <i className="fa-light fa-eye-slash"></i>
                  )}
                </button>
              </div>

               
            </div>

            {!wait ? (
              <div className="mt-2 text-center">
                <button
                  className="btn btn-registro"
                  onClick={register}
               
                >
                  Registrar
                </button>
              </div>
            ) : (
              <div className="text-center">
                <Loading />
              </div>
            )}
          </div>
        </form>
      </div>
      <ToastContainer position="top-center" />
    </>
  );
}
