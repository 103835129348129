import React, { useState, useEffect } from "react";
import fondo from "../../globalassets/img/fondo.svg";
import { getSession, setSession } from "../../services/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import "./assets/user.css";
import icoStart from "../../globalassets/img/ico-start.svg";
import icoCancel from "../../globalassets/img/ico-cancel.svg";
import icoExit from "../../globalassets/img/ico-exit.svg";
import icoKey from "../../globalassets/img/ico-key.svg";
import Tarjetas from "../tarjetas/tarjetas";
import servicesApi from "../../services/services";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import Loading from "../layout/Loading";

import ModalSuccess from "../ModalConfirm/ModalSuccess";

export default function User() {
  const [isPremium, setIsPremium] = useState(false);
  const [infoUser, setInfoUser] = useState({});
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [showModalSuccessCancel, setShowModalSuccessCancel] = useState(false);
  const [descCancelError, setDescCancelError] = useState("");
  const navigate = useNavigate();

  function logout() {
    setSession(null);
    navigate("/");
  }
  const handlePremiumClick = () => {
    navigate("/private/premium");
  };

  function check_user_status() {
    servicesApi.useService(true);
    servicesApi.get("payments/check_user_status").then((response) => {
      setIsPremium(response.data.data.isPremium);
      setInfoUser(response.data.data);
      let s = getSession();
      setSession({ ...s, isPremium: response.data.data.isPremium });
    });
  }
  useEffect(() => {
    check_user_status();
  }, []);

  function cancelSubscription() {
    setLoadingCancel(true);
    setDescCancelError("");
    servicesApi.useService(true);
    servicesApi.get("payments/cancel_subscription").then(
      (response) => {
        if (response.data.error == 0) {
          setShowModalSuccessCancel(true);
          check_user_status();
        } else {
          setDescCancelError(response.data.message);
        }

        setLoadingCancel(false);
      },
      () => {
        setDescCancelError("Error, intente más tarde");
        setLoadingCancel(false);
      }
    );
  }

  function callbackRegitroTarjeta() {
    navigate("/private/premium");
  }

  return (
    <>
      <img src={fondo} alt="logo" className="fondo-custom" />
      <div className="bg-full2">
        <div className="profile-data">
          <h4 className="profile-name">{infoUser.name}</h4>
          <label htmlFor="" className="profile-email">
            {infoUser.email}
          </label>
        </div>
        <div className="text-center mb-3">
          {isPremium && (
            <>
              <label htmlFor="" className="label-premium">
                {" "}
                <img src={icoStart} height={25} alt="" /> Suscripción Activa
              </label>
              <div className="text-center">
                <span className="text-sm">
                  {infoUser.status_subscription == "active"
                    ? "Renovación automatica"
                    : "Suscripción cancelada. Expira"}{" "}
                  {infoUser.expiration}
                </span>
              </div>
            </>
          )}
          {isPremium && infoUser.status_subscription == "active" && infoUser.id_parent == "0" && (
            <div className="text-center d-flex justify-content-center align-items-center flex-column">
              {!loadingCancel ? (
                <>
                  <button
                    className="btn-grey w-100"
                    onClick={() => {
                      setShowModalConfirm(true);
                    }}
                  >
                    Cancelar suscripción
                  </button>
                  
                </>
              ) : (
                <Loading />
              )}
            </div>
          )}
          {descCancelError != "" && (
            <div className="text-center mb-2">
              <label htmlFor="" className="lbl-error">
                {descCancelError}
              </label>
            </div>
          )}
          {(!isPremium || infoUser.status_subscription == "cancel") && (
            <>
              <div className="alert alert-primary">
                Para tener acceso al contenido, debe tener una suscripción
                mensual o anual
              </div>
              {
                infoUser.id_parent == "0" && (
                  <div className="text-center">
                    <button
                      className="btn-make-premium"
                      onClick={handlePremiumClick}
                    >
                      Pagar suscripción
                    </button>
                  </div>
                )
              }
              
            </>
          )}
          {
            infoUser.id_parent == "0" && (
              <button
              className="btn btn-primary mt-4 w-100"
              onClick={() => {
                navigate("/private/adminusuarios");
              }}
              >
                Administrar otros usuarios
              </button>
            )
          }
         
        </div>

        <hr />
        {
          infoUser.id_parent == "0" && (
            <>
               <div className="panel mb-5">
                <div className="logo-serfinsa2">
                    <img
                      src="https://res.cloudinary.com/djyulfuk6/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1704999606/logo-serfinsa_w54rkm.jpg?_s=public-apps"
                      alt="Serfinsa Logo" className="serfinsa-img"
                    />
                </div>
                <label htmlFor="" className="panel-title">
                  Mis tarjetas
                </label>
                <Tarjetas callback={callbackRegitroTarjeta} />
            </div>
            <hr />
            </>
          )
        }
        

        <div className="text-center mb-3">
          <button
            className="btn-line button-width "
            onClick={() => {
              navigate("/private/cambiopass");
            }}
          >
            {" "}
            <img src={icoKey} alt="" height={25} /> Cambiar contraseña
          </button>
        </div>
        <div className="text-center">
          <button
            className="btn-line  button-width"
            onClick={() => {
              logout();
            }}
          >
            {" "}
            <img src={icoExit} alt="" height={25} />
            Cerrar sesión
          </button>
        </div>
      </div>
      <ModalConfirm
        show={showModalConfirm}
        onHide={() => {
          setShowModalConfirm(false);
        }}
        text="¿Está seguro de cancelar su suscripción?"
        onConfirm={cancelSubscription}
      />
      <ModalSuccess
        show={showModalSuccessCancel}
        onHide={() => {
          setShowModalSuccessCancel(false);
        }}
        text="Suscripción cancelada con éxito"
      />
    </>
  );
}
