import { useEffect, useState } from "react";
import categoriesServices from "../../services/categories";
import { Oval } from "react-loader-spinner";
import { useParams, useNavigate } from "react-router-dom";
import { Document, Page } from "react-pdf";
import servicesApi from "../../services/services";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import MostrarPDF from "../buscar/mostrarpdf";

export default function Favoritos() {
	const [loading, setLoading] = useState(false);
	const [subCategories, setSubCategories] = useState([]);

	const [query, setQuery] = useState('');
	const [subCategoriesFilters, setSubCategoriesFilters] = useState([]);

	const [favorites, setFavorites] = useState([]);
	const [showConfirmar, setShowConfirmar] = useState(false);
	const [itemEliminar, setItemEliminar] = useState(0);
	const [pdfMode,setPdfMode] = useState(false);
	const [itemPDF,setItemPDF] = useState(null);
	const [currentPDFLink,setCurrentPDFLink] = useState(null);

	const navigate = useNavigate();

	const handledQuery = ({ target }) => {
		const value = target.value;
		setQuery(value);
	}

	function getFavorites() {
		setLoading(true);
		servicesApi.useService(true);
		servicesApi.get("favoritos_get").then((response) => {
			setFavorites(response.data.favoritos);
			setLoading(false);
		});
	}
	useEffect(() => {
		getFavorites();
	}, []);
	function submitEliminar() {
		servicesApi.useService(true);
		servicesApi.post("guardar_favorito", { id_law: itemEliminar }).then((response) => {
			let _favs = favorites;
			_favs = _favs.filter(x => x.id_law != itemEliminar);
			setFavorites(_favs);
			setItemEliminar(0);
		});
	}

	function visorPDF(item){
		 
		  setCurrentPDFLink(item.source_path);
		  setItemPDF(item);
		  //console.log(_link);
		  setPdfMode(true);
	  }


	return (
		<div className="bg-full2 h100vh custom-houdini">
			<div className="container-custom">
				<p className="container-back" onClick={() => { navigate(-1); }}>
					<i className="fa-sharp fa-solid fa-chevron-left"></i>
					<h3 className="name-custom">Favoritos</h3>
				</p>

				<div className="container-cards1">
					{loading ? (
						<div className="col-12 mt-25 ">
							<Oval
								type="ThreeDots"
								color="#00BFFF"
								height={80}
								width={80}
								strokeWidth={3}

							/>
						</div>
					) : (
						favorites.map((item, index) => (
							<div className="card-container" key={index}>
								<div className="card">
									<div className="card-body2">
										<div className="row">
											<div className="col-10">
												<p
													className="txt-custom04"
													onClick={()=>{visorPDF(item)}}
												>
													{item.name}
												</p>
											</div>
											<div className="col-2">
												<span className="btn-remove-fav" onClick={() => {
													setShowConfirmar(true);
													setItemEliminar(item.id_law)
												}}>
													<i className="fa-solid fa-xmark"></i>
												</span>
											</div>
										</div>


									</div>
								</div>
							</div>
						))

					)}
					{!loading && favorites.length == 0 && (<h6>No tiene favoritos guardados</h6>)}
				</div>
			</div>
			<ModalConfirm
				text="¿Está seguro de quitar este elemento de favoritos?"
				show={showConfirmar}
				onHide={() => { setShowConfirmar(false) }}
				onConfirm={submitEliminar}
			/>
			{
				pdfMode && (
					<MostrarPDF pdfLink={currentPDFLink} visorMode={true} close={() => { setPdfMode(false) }} item={itemPDF} />
				)
			}
		</div>
	);
}
