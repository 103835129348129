import React from "react";
import { NavLink } from "react-router-dom";
import "./assets/navBar.css";
import Home from "./assets/img/Home.png";
import HomeActive from "./assets/img/HomeActive.png";
import Phone from "./assets/img/Phone.png";
import PhoneActive from "./assets/img/PhoneActive.png";
import Search from "./assets/img/Search.png";
import SearchActive from "./assets/img/SearchActive.png";
import User from "./assets/img/User.png";
import UserActive from "./assets/img/UserActive.png";
import Users from "./assets/img/Users.png";
import UsersActive from "./assets/img/UsersActive.png";
import { getSession } from "../../services/AuthContext";

const NavBar = () => {
  let u = getSession();
  return (
    <div className="navbar">
      <div className="nav-link">
        <NavLink end className="link-nav" to="/private/categoria">
          <div className="bg-icon-nav">
            <img src={Home} alt="logo" className="icon-nav" />
            <img src={HomeActive} alt="logo" className="icon-nav-active" />
          </div>
        </NavLink>
      </div>
      <div className="nav-link">
        <NavLink className="link-nav" to="/private/buscar">
          <div className="bg-icon-nav">
            <img className="icon-nav" src={Search} alt="logo" />
            <img src={SearchActive} alt="logo" className="icon-nav-active" />
          </div>
        </NavLink>
      </div>
      <div className="nav-link">
        <NavLink className="link-nav" to="/private/directorio">
          <div className="bg-icon-nav">
            <img className="icon-nav" src={Phone} alt="logo" />
            <img className="icon-nav-active" src={PhoneActive} alt="logo" />
          </div>
        </NavLink>
      </div>
      <div className="nav-link">
        <NavLink className="link-nav" to="/private/User">
          <div className="bg-icon-nav">
            <img className="icon-nav" src={User} alt="logo" />
            <img className="icon-nav-active" src={UserActive} alt="logo" />
          </div>
        </NavLink>
      </div>
      {
        u.isAdmin == 1 && (
       
      <div className="nav-link">
        <NavLink className="link-nav" to="/private/superadmin">
          <div className="bg-icon-nav">
            <img className="icon-nav" src={Users} alt="logo" height={22} />
            <img className="icon-nav-active" src={UsersActive} alt="logo" height={22} />
          </div>
        </NavLink>
      </div>
      )
      }
    </div>
  );
};

export default NavBar;
