import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import servicesApi from "../../services/services";
import "./superAdminUsuarios.css";
import { Modal, Button } from "react-bootstrap";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import ModalSuccess from "../ModalConfirm/ModalSuccess";
import SuperAdminRegistro from "./superAdminRegistro";

export default function SuperAdminUsuarios(props) {

	const navigate = useNavigate();
	const [usuarios, setUsuarios] = useState([]);
	const [userSelected, setUserSelected] = useState({});
	const [openModal, setOpenModal] = useState(false);
	const [tarifa, setTarifa] = useState({});
	const [tarifas, setTarifas] = useState([]);

	const [buscar, setBuscar] = useState('');

	const [showModalConfirm, setShowModalConfirm] = useState(false);

	const [isBottom, setIsBottom] = useState(false);
	const [lastH, setLastH] = useState(0);
	const [pag, setPag] = useState(1);


	const [openModalRegistro, setOpenModalRegistro] = useState(false);


	function check_user_status() {
		servicesApi.useService(true);
		servicesApi.get("payments/check_user_status").then((response) => {
			//setId(response.data.data.id_user);
			if(response.data.data.is_admin!=1){
				navigate("/")
			}
		});
	}

	function getUser(clear_prev) {
		
		let params = {
			pag: clear_prev ? 1 : pag,
			search: buscar
		}
		servicesApi.useService(true);
		servicesApi.get("superadmin/get_users", params).then((response) => {
			let news;
			if (clear_prev) {
				news = [...response.data.users];
			} else {
				news = [...usuarios, ...response.data.users];
			}
			setUsuarios(news);
		});
	}
	function getTarifas() {
		servicesApi.useService(true);
		servicesApi.get("get_tarifas").then((response) => {
			setTarifas(response.data.tarifas);
			setTarifa(response.data.tarifas[0]);
		});
	}

	useEffect(() => {
		check_user_status();
		//getUser();
		getTarifas();
	}, []);

	function handleModal(u) {
		setUserSelected(u);
		setOpenModal(true);
	}

	function activarSuscripcion() {
		let post = {
			id_usuario: userSelected.id,
			tarifa: tarifa.id
		}
		servicesApi.useService(true);
		servicesApi.post("superadmin/activar_suscripcion", post).then((response) => {
			if (response.data.error == 0) {
				let _user = response.data.user;
				let _usuarios = usuarios;
				_usuarios = _usuarios.map((x) => {
					if (x.id == _user.id) {
						x = _user;
					}
					return x;
				});
				setUsuarios(_usuarios);
				setUserSelected(_user);
			}
		});
	}

	useEffect(() => {

		let timer = setTimeout(() => {
			setLastH(0);

			setPag(1);
			//setUsuarios([]);
			//console.log('buscar');
			getUser(true)
		}, 700);

		return () => { clearTimeout(timer) }
	}, [buscar]);


	const handleScroll = () => {
		const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
		const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
		const clientHeight = document.documentElement.clientHeight || window.innerHeight;

		// Check if the user has scrolled to the bottom
		const reachedBottom = scrollTop + clientHeight >= scrollHeight - 10; // You can adjust the threshold

		setIsBottom(reachedBottom);
	};

	useEffect(() => {
		// Attach the scroll event listener
		window.addEventListener('scroll', handleScroll);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []); // Empty dependency array means this effect runs once after the initial render

	useEffect(() => {
		let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
		if (isBottom && (lastH + 100) < scrollTop) {
			let _pag = pag + 1;
			setPag(_pag);
			setLastH(scrollTop);
		}
	}, [isBottom]);
	useEffect(() => {
		if (pag > 1) {
			getUser();
		}
	}, [pag]);

	function callbackRegistro(data){
		setOpenModalRegistro(false);
		console.log(data)
		getDetailUser(data.data.id);
	}
	function getDetailUser(id){
		let params = {
			id_user:id
		}
		servicesApi.useService(true);
		servicesApi.get("superadmin/info_user",params).then((response) => {
			if (response.data.error == 0) {
				let _user = response.data.user;
				 
				setUserSelected(_user);
				setOpenModal(true);
			}
		});
	}
	return (
		<>
			<div className="contenedor">
				<header className="w-100">

					<h2 className="fw-bold">Administrador de usuarios</h2>

				</header>
				<div className=" mt-4 text-right">
					<button
						type="button"
						className="btn btn-secondary"
						onClick={() => setOpenModalRegistro(true)}
					>
						Nuevo usuario
					</button>

				</div>
				<div className="buscador mt-3">
					<input type="text" className="input-buscador" value={buscar} onChange={(e) => { setBuscar(e.target.value) }} />
				</div>

				<div className="mt-4 mb-5">
					{usuarios.map((usuario, index) => (
						<div
							key={index}
							className="card mb-2"

						>
							<div className="card-body">
								<div className="row">
									<div className="col-8">
										<h3 className="user-name">{usuario.name}</h3>
										<span
											className="text-muted d-block"
											style={{ fontSize: "12px" }}
										>
											{usuario.email}
										</span>

									</div>
									<div className="col-4 text-center" onClick={() => { handleModal(usuario) }}>
										<span className={`status-sub ${(usuario.status_subscription == 1 && 'label-active')}`}  >{usuario.status_subscription_str}</span>
									</div>
								</div>
							</div>
						</div>
					))}
					{
						usuarios.length == 0 && (
							<div className="sin-usuarios"><h4>No tiene usuarios registrados</h4></div>
						)
					}
				</div>

			</div>

			<Modal show={openModal} onHide={() => { setOpenModal(false) }} centered>

				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<div className="text-left mb-4">
						<div className="item-data">
							<label htmlFor="">Cliente</label>
							<span>{userSelected.name}</span>
						</div>
						<div className="item-data">
							<label htmlFor="">Correo electrónico</label>
							<span>{userSelected.email}</span>
						</div>
						<div className="item-data">
							<label htmlFor="">Fecha de registro</label>
							<span>{userSelected.fecha_creacion}</span>
						</div>
						<div className="row">
							<div className="col-6 d-block">
								<div className="item-data">
									<label htmlFor="">Estatus de suscripción</label>
									<span className={userSelected.status_subscription == 1 && 'label-active'}>{userSelected.status_subscription_str}</span>
								</div>
							</div>
							<div className="col-6  d-block">
								<div className="item-data">
									<label htmlFor="">Fecha expiración suscripción</label>
									<span>{userSelected.expire_date}</span>
								</div>
							</div>
						</div>
						{
							userSelected.status_subscription != 1 && (
								<div>
									<div className="text-center">
										<b>Activar suscripcíon</b>
										<select name="" id="" className="form-control" onChange={(e) => {
											setTarifa(tarifas.filter(x => x.id == e.target.value)[0]);
										}} value={tarifa.id}>
											{
												tarifas.map((x) => (
													<option value={x.id}>{x.nombre_suscripcion}</option>
												))
											}
										</select>
									</div>
									<div className="text-center mt-3">
										<button className="btn btn-primary" onClick={() => { setShowModalConfirm(true) }}>Activar</button>
									</div>
								</div>
							)
						}

					</div>

				</Modal.Body>


			</Modal>
			<ModalConfirm
				show={showModalConfirm}
				onHide={() => {
					setShowModalConfirm(false);

				}}
				text="¿Está seguro activar la suscripción?"
				onConfirm={activarSuscripcion}
			/>

			<Modal show={openModalRegistro} onHide={() => { setOpenModalRegistro(false) }} centered>

				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					 <SuperAdminRegistro callback={callbackRegistro}/>

				</Modal.Body>


			</Modal>
		</>
	)
}