import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaCreditCard } from "react-icons/fa";

import "./tarjetas.css";

import icoCancel from "../../globalassets/img/ico-cancel.svg";
import servicesApi from "../../services/services";
import Loading from "../layout/Loading";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import logoSerfinsa from "../../globalassets/img/logo-serfinsa.png";

export default function Tarjetas(props) {
	const [showModal, setShowModal] = useState(false);
	const [wait, setWait] = useState(false);
	const [errorDesc, setErrorDesc] = useState('');
	const [idTarjetaEliminar, setIdTarjetaEliminar] = useState(0);
	const [tarjetas, setTarjetas] = useState([]);
	const [waitTarjetas, setWaitTarjetas] = useState(false);
	const seleccionMode = props.seleccionMode == "true" ? true : false;

	//ABRIR MODAL
	const handleModalToggle = () => {
		setShowModal(!showModal);
	};

	const [showConfirmEliminarTarjeta, setShowConfirmEliminarTarjeta] = useState(false);

	const [formData, setFormData] = useState({
		numero_tarjeta: "",
		nombre: "",
		fecha_expiracion: "",
		cvv: "",
	});
	const handleChange = (event) => {
		const { name, value } = event.target;
		let formattedValue = value;


		if (name === "numero_tarjeta") {
			const digitsOnly = value.replace(/-/g, "");
			const maxLength = 16;
			formattedValue = digitsOnly.slice(0, maxLength);
			if (formattedValue.length > 4) {
				formattedValue = formattedValue.replace(/(.{4})/g, "$1-");
			}
			// Eliminar el último guion si existe
			formattedValue = formattedValue.replace(/-$/, "");
		}

		if (
			name === "fecha_expiracion" &&
			value.length > 2 &&
			!value.includes("/")
		) {
			formattedValue = value.slice(0, 2) + "/" + value.slice(2);
		}

		if (name === "cvv") {
			const digitsOnly = value.replace(/\D/g, "");
			const maxLength = 3;
			formattedValue = digitsOnly.slice(0, maxLength);
		}



		setFormData({
			...formData,
			[name]: formattedValue,
		});
	};


	const handleSubmit = (event) => {

		event.preventDefault();

		console.log("enviando data:", formData);

		setWait(true);
		setErrorDesc('');
		servicesApi.useService(true);
		servicesApi.post("payments/register_card", formData).then((response) => {
			console.log(response)
			if (response.data.error != 0) {
				setErrorDesc(response.data.error);
			} else {
				setFormData({
					numero_tarjeta: "",
					nombre: "",
					fecha_expiracion: "",
					cvv: "",
				});
				setShowModal(false);
				getTarjetas();
				if(props.callback && typeof props.callback == 'function'){
					props.callback(true);
				}
			}
			setWait(false);

		});


	};

	function handleOpenConfirmTarjeta(_id) {
		setIdTarjetaEliminar(_id);
		setShowConfirmEliminarTarjeta(true);
	}

	function confirmEliminar() {
		setWaitTarjetas(true);
		setTarjetas([]);
		servicesApi.useService(true);
		servicesApi.post("payments/remove_card", { id_token: idTarjetaEliminar }).then((response) => {
			getTarjetas();
		});
	}

	function getTarjetas() {

		setWaitTarjetas(true);
		servicesApi.useService(true);
		servicesApi.get("payments/user_cards", {}).then((response) => {
			console.log(response)
			if (response.data.error == 0) {
				setTarjetas(response.data.cards);
			}
			setWaitTarjetas(false);
		});
	}
	useEffect(() => {
		getTarjetas();
		 
	}, []);
	useEffect(()=>{
	 
		
	},[tarjetas]);

	function selectCard(item) {
		if(!seleccionMode) return;

		let _tarjetas = [...tarjetas];
		_tarjetas.map((x) => {
			if (!x.selected && item.id_token == x.id_token) {
				x.selected = true;
			} else {
				x.selected = false;
			}
			return x;
		});
		if (typeof props.getIdTokenCard == "function") {
			let selected = _tarjetas.filter(x => x.selected)[0];
			if (selected && selected.id_token) {
				props.getIdTokenCard(selected.id_token);
			} else {
				props.getIdTokenCard(0);
			}
		}
		setTarjetas(_tarjetas);
	}

	return (
		<>
			<div className="componente-tarjetas">
				{
					tarjetas.map((item, index) => (
						<div key={index} className={`item-tarjeta ` + (item.selected && 'selected')} onClick={() => { selectCard(item) }}>
							<span className="number-card">**** {item.last_digits}</span>
							{
								!seleccionMode && (
									<span className="ico-remove" title="Eliminar tarjeta" onClick={() => { handleOpenConfirmTarjeta(item.id_token) }}>
										<img src={icoCancel} height={25} alt="Eliminar tarjeta" />
									</span>
								)
							}

						</div>
					))
				}
				{
					!waitTarjetas && tarjetas.length == 0 && (
						<div className="item-tarjeta no-card">
							No tiene tarjetas registradas
						</div>
					)
				}
				{
					waitTarjetas && <div className="text-center"><Loading /></div>
				}
				{
					tarjetas.filter(x => x.selected).length == 0 && (
						<div className="text-center">
							<button className="btn-grey" onClick={() => { setShowModal(true) }}>Registrar una tarjeta de crédito</button>
						</div>
					)
				}

			</div>
			<Modal show={showModal} onHide={handleModalToggle} centered>
				<form onSubmit={handleSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>Registrar Tarjeta</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="container-pago-date">
							<div className="row mb-3">
								<div className="col-10">
									<input
										type="text"
										className="input-custom"
										placeholder="Número de tarjeta"
										id="numero_tarjeta"
										name="numero_tarjeta"
										value={formData.numero_tarjeta}
										onChange={handleChange}
										required
									/>
								</div>
								<div className="col-2 align-self-center">
									<FaCreditCard size={32} color="black" />
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-5">
									<input
										type="text"
										className="input-custom"
										id="fecha_expiracion"
										name="fecha_expiracion"
										maxLength="5"
										placeholder="MM/AA"
										value={formData.fecha_expiracion}
										onChange={handleChange}
										required
									/>
								</div>
								<div className="col-5">
									<input
										type="number"
										className="input-custom"
										placeholder="CVV"
										id="cvv"
										name="cvv"
										value={formData.cvv}
										onChange={handleChange}
										required
									></input>
								</div>
							</div>
							{
								errorDesc != '' && (
									<div className="text-center">
										<label htmlFor="" className="lbl-error">{errorDesc}</label>
									</div>
								)
							}


						</div>
						<div className="info-seguridad">
						<i class="fa-regular fa-lock"></i> Pagos seguros a travez de sistema de tokenización
						</div>
						<div style={{textAlign:'center'}}>
							<img src={logoSerfinsa} alt="" style={{height:'50px'}} />
						</div>
					</Modal.Body>
					<Modal.Footer>
						{
							!wait ?
								<Button variant="primary" type="submit" >
									Confirmar
								</Button>
								:
								<Loading></Loading>
						}


					</Modal.Footer>
				</form>
			</Modal>
			<ModalConfirm text="¿Está seguro de eliminar esta tarjeta?"
				show={showConfirmEliminarTarjeta}
				onHide={() => { setShowConfirmEliminarTarjeta(false) }}
				onConfirm={confirmEliminar} />
		</>
	)
}