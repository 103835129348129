import { useEffect, useState } from "react";
import categoriesServices from "../../services/categories";
import { useParams, useNavigate } from "react-router-dom";
import { usePdfSource } from "../../store/index";
//import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

export default function DecretosPdf(subcategorias) {
  //const [setLoading] = useState(false);
  const [setSubCategories] = useState([]);
  const { idCategoria } = useParams();
  const navigate = useNavigate();

  // const navigate = useNavigate();

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  const pdfSource = usePdfSource((state) => state.pdfSource);
  useEffect(() => {
    const getSubCategories = async () => {
     // setLoading(true);
      const response = await categoriesServices.getPdfCategoryById(idCategoria);
      setSubCategories(response.data);
     // setLoading(false);
    };
    getSubCategories();
    console.log(pdfSource);
  }, []);

  return (
    <div className="bg-full h100vh custom-houdini">
        <div className="container-custom-pdf">

        <p className="txt-custom03" onClick={() => {navigate(-1);}}>
          <i className="fa-sharp fa-solid fa-chevron-left back-pdf"></i>
          <i className="fa-sharp fa-solid fa-bookmark"></i>
        </p>
 
      <div className="container-pdf">
      {/* <iframe src={"https://docs.google.com/viewer?url="+encodeURIComponent(pdfSource)+"&embedded=true&rm=minimal"} width="100%" height="600px" frameborder="0" scrolling="no" id="visor_pdf"></iframe> */}

      <iframe src={"http://localhost/pdfjs/web/viewer.html?file="+encodeURIComponent('http://localhost/pdf-test.pdf')} width="100%" height="600px" frameborder="0" scrolling="no" id="visor_pdf"></iframe>
      <div className="disable-external-open"> </div>
      </div>
      </div>
    </div>
  );
}
