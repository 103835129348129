import React from "react";
import fondo from "../../globalassets/img/fondo.svg";
import "./assets/SendEmail.css";

const SendEmail = () => {
  return (
    <div>
      <img src={fondo} alt="logo" className="fondo-custom" />
      <div className="bg-full-2">
        <div className="mt-5 form-login">
          <h3 className="title-txt-login-2">Ingresar Correo</h3>
          <input
            type="email"
            className="mt-3 form-control"
            placeholder="Ingrese correo"
          />
          <div className="mt-3 text-center">
            <button className="btn btn-registro" to="/private/categoria">
              Enviar correo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendEmail;
