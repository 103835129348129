import React, { useState } from "react";
import fondo from "../../globalassets/img/fondo.svg";
import { NavLink } from "react-router-dom";
import "./assets/login.css";
import { GoogleLogin } from "@react-oauth/google";
import Facebook from "../Facebook/facebook";
import axios from "axios";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";

import servicesApi from "../../services/services";
import Loading from "../layout/Loading";
import { setSession } from "../../services/AuthContext";

const Login = () => {

    const [passwordType, setPasswordType] = useState("password");

    const [formData, setFormData] = useState({ usuario: "", clave: "" });

    const [wait, setWait] = useState(false);

    const [mode,setMode] = useState('login');

    const navigate = useNavigate();


    function validaLogin(e) {

        e.preventDefault();

        setWait(true);
        const params = { ...formData, pwdkey: "M4rd0queo1" };


        servicesApi.useService(true);
        servicesApi.post("validaLogin", params).then(async (response) => {
            console.log(response);
            
            if (response.data.error == '1') {
                toast.error(response.data.mensaje);
            } else {
                
                setFormData({});
                let d = {
                    token: response.data.data.token,
                    isAdmin:response.data.data.isAdmin ? 1:0
                }
                setSession(d);
                let checkPremium = await check_user_status();
                let isPremium = checkPremium.data.data.isPremium;
               
                d = {...d,isPremium}
                setSession(d);

                toast.success(response.data.mensaje);
                if(d.isAdmin==1){
                    navigate('/private/superadmin');
                }else{
                    navigate('/private/categoria');
                }
            }
            setWait(false);
        }, () => {
            setWait(false);
            toast.error("Error, intente más tarde");
        });

    }


    const handlePasswordChange = (evnt) => {
        //setFormData({ ...formData, clave: evnt.target.value });
    };

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };

    function submitRecuperar(e){
        e.preventDefault();
        
        setWait(true);
        const params = { ...formData, pwdkey: "M4rd0queo1" };


        servicesApi.useService(true);
        servicesApi.post("recuperar_pass", params).then((response) => {
            console.log(response);
            setWait(false);
            setFormData({usuario:''});
            toast.success(response.data.mensaje);
        }, () => {
            setWait(false);
            toast.error("Error, intente más tarde");
        });

        return false;
    }

    async function check_user_status() {
		servicesApi.useService(true);
		return servicesApi.get("payments/check_user_status");
	}

    return (
        <>
            <img src={fondo} alt="logo" className="fondo-custom" />
            <div className="bg-full">
                <div className="bar-top-fixed">

                    <span className="ico-back" onClick={() => { navigate('/') }}></span>

                </div>
                <form action="" onSubmit={validaLogin} style={{display:mode=='login'?'block':'none'}}>
                    <div className="container-bienvenida">
                        <div className="form-login">
                            <h3 className="title-txt-login">Ingresar</h3>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Correo electrónico"
                                    required
                                    onChange={(e) => { setFormData({ ...formData, usuario: e.target.value }) }} value={formData.usuario}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control w-100"
                                    type={passwordType}
                                    placeholder="Contraseña"
                                    onChange={(e) => { setFormData({ ...formData, clave: e.target.value }) }} value={formData.clave}
                                    required
                                />
                                <button
                                    className="btn-show-pass"
                                    type="button"
                                    onClick={togglePassword}
                                >
                                    {passwordType === "password" ? (
                                        <i className="fa-light fa-eye"></i>
                                    ) : (
                                        <i className="fa-light fa-eye-slash"></i>
                                    )}
                                </button>
                            </div>
                        </div>
                        <br />

                        {
                            !wait ? (
                                <div className="text-center">
                                    <button className="btn btn-registro"  >Ingresar</button>
                                </div>
                            ) : (
                                <div className="text-center">
                                    <Loading />
                                </div>
                            )
                        }

                        <br />
                        <div className="text-center"></div>
                        <NavLink className="btn btn-registro" onClick={()=>{setMode("forgot")}}>
                            Olvidé mi contraseña
                        </NavLink>
                    </div>
                </form>
                <form action="" onSubmit={submitRecuperar} style={{display:mode=='forgot'?'block':'none'}} >
                <div className="container-bienvenida">
                        <div className="form-login">
                            <h3 className="title-txt-login">Recuperar contraseña</h3>
                            <p style={{color:"#777"}}>Se enviará una contraseña temporal a tu correo electrónico</p>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Correo electrónico"
                                    required
                                    onChange={(e) => { setFormData({ ...formData, usuario: e.target.value }) }} value={formData.usuario}
                                />
                            </div>
                            
                        </div>
                        <br />

                        {
                            !wait ? (
                                <div className="text-center">
                                    <button className="btn btn-registro"  >Enviar</button>
                                </div>
                            ) : (
                                <div className="text-center">
                                    <Loading />
                                </div>
                            )
                        }

                        <br />
                        <div className="text-center"></div>
                        <NavLink className="btn btn-registro" onClick={()=>{setMode("login")}}>
                            Regresar
                        </NavLink>
                    </div>
                </form>
            </div>
            <ToastContainer />

        </>
    );
};



export default Login;
