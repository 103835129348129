import React, { useState } from "react";
import fondo from "../../globalassets/img/fondo.svg";
import { useNavigate } from "react-router-dom";
import "./addUser.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

import servicesApi from "../../services/services";
import Loading from "../layout/Loading";
import { setSession } from "../../services/AuthContext";

export default function Adduser(props) {
  const { id } = useParams();
  const [passwordType, setPasswordType] = useState("password");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    is_child: props.isChild?1:0
  });

  const [terminos, setTerminos] = useState(false);
  const [wait, setWait] = useState(false);

  const navigate = useNavigate();

  function register(e) {
    e.preventDefault();

    setWait(true);
    const params = { ...formData, pwdkey: "M4rd0queo1" };

    servicesApi.useService(true);
    servicesApi.post("registrar", params).then(
      (response) => {
        console.log(response);
        setWait(false);
        if (response.data.error == "1") {
          toast.error(response.data.mensaje);
        } else {
          setFormData({});
          toast.success(response.data.mensaje, {
            onClose: () => {
              // reload page
              window.location.reload(false);
            },
          });
        }
      },
      () => {
        setWait(false);
        toast.error("Error, intente más tarde");
      }
    );
  }

  const handlePasswordChange = (evnt) => {
    setFormData({ ...formData, password: evnt.target.value });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <>
      <img src={fondo} alt="logo" className="fondo-custom" />
      <div className="bg-full h100vh">
        <div className="bar-top-fixed">
          <span
            className="ico-back"
            onClick={() => {
              navigate("/private/adminusuarios");
            }}
          ></span>
        </div>
        <form action="" onSubmit={register}>
          <div className="container-bienvenida">
            <div className="form-register">
              <label className="title-txt-login">Registrar usuario</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre Completo"
                  required
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                  value={formData.name}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Correo electrónico"
                  required
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                  value={formData.email}
                />
              </div>
              <div className="form-group">
                <input
                  type={passwordType}
                  className="form-control"
                  placeholder="Contraseña"
                  required
                  onChange={handlePasswordChange}
                />

                <button
                  className="btn-show-pass"
                  type="button"
                  onClick={togglePassword}
                >
                  {passwordType === "password" ? (
                    <i className="fa-light fa-eye"></i>
                  ) : (
                    <i className="fa-light fa-eye-slash"></i>
                  )}
                </button>
              </div>

              <div className="custom-terminos">
                <input
                  type="checkbox"
                  style={{ zoom: 1.5 }}
                  onClick={(e) => {
                    setTerminos(e.target.checked);
                  }}
                />{" "}
                <p className="text-terminos">Aceptar terminos y condiciones</p>
              </div>
            </div>

            {!wait ? (
              <div className="mt-2 text-center">
                <button
                  className="btn btn-registro"
                  onClick={register}
                  disabled={!terminos}
                >
                  Registrar usuario
                </button>
              </div>
            ) : (
              <div className="text-center">
                <Loading />
              </div>
            )}
          </div>
        </form>
      </div>
      <ToastContainer position="top-center" />
    </>
  );
}
