import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import categoriesServices from "../../services/categories";
import { useStoreCategory, useNameCategory } from "../../store";
import { Oval } from "react-loader-spinner";
import { getSession } from "../../services/AuthContext";

export default function Categorias() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const category = useStoreCategory((state) => state.category);
  const setCategory = useStoreCategory((state) => state.setCategory);
  const setCategoryName = useNameCategory((state) => state.setCategoryName);
  const  user_sess  = getSession();

  useEffect(()=>{
    if(!user_sess.isPremium){
      navigate("/private/User");
    }
  },[])

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);
      const response = await categoriesServices.getCategories();
      setCategory(response.data);
      setLoading(false);
    };
    getCategories();
  }, []);

  return (
    <>
      <img src='https://raw.githubusercontent.com/KevinArce/Extras/main/img/fondo.svg' alt="logo" className="fondo-custom" />

      <div className="bg-full h100vh custom-houdini">
        <div className="container-custom">
          <h3 className="custom-titleCategories">¡Bienvenido a Blue Dot!</h3>
          <h3 className="custom-subtitleCategories">Categorias</h3>
          <div className="container-options">
            <button className="option-custom" onClick={() => navigate("")}>
              Contenido
            </button>
            <button className="option-custom2" onClick={() => navigate("/private/favoritos")}>
              Favoritos
            </button>
          </div>
          <div className="container-cards1">
            <div className="row">
              {loading ? (
                <div className="col-12 mt-25">
                  <Oval
                    type="ThreeDots"
                    color="#00BFFF"
                    height={80}
                    width={80}
                    strokeWidth={3}
                  />
                </div>
              ) : (
                category.map((categorias, index) => (
                  <div className="col-6" key={index}>
                    <div className="card6 cursor-pointer" onClick={() => {
                      if(categorias.id !=1240){
                        setCategoryName(categorias.name);
                        navigate(`/private/subcategoria/${categorias.id}`);
                      }else{
                        navigate(`/private/directorio`);
                      }
                    }}>
                      <img
                        className="imagen imagen-category"
                        src={categorias.image}
                        alt="logo"
                      />
                      <div className="container-txtCard">
                        <p className="txt-custom01">
                          {categorias.name}
                          <div className="container-iconCategories">
                            <i className="fa-sharp fa-solid fa-circle-chevron-right"></i>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
