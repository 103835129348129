import React,{useState,useEffect} from "react";

import { NavLink,useNavigate } from "react-router-dom";

import servicesApi from "../../services/services";
import Loading from "../layout/Loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Cambiarpass(){

	const navigate = useNavigate();

	const [formData, setFormData] = useState({ clave: "", clave2: "" });

	const [passwordType, setPasswordType] = useState("password");

	const [wait, setWait] = useState(false);
	const [toggle,setToggle] = useState({});

	
	function submitForm(e){
		e.preventDefault();

        setWait(true);
		const params = {...formData}
		servicesApi.useService(true);
        servicesApi.post("changePass", params).then( (response) => {
			 
			if(response.data.error == 1){
				 
				toast.error(response.data.message);
			}else{
				toast.success(response.data.message);
				setFormData({ clave: "", clave2: "" });
			}
			setWait(false);
		},()=>{
			toast.error("Error, intente más tarde");
			setWait(false);
		});
		return false;

	}
	return(<>
			<div className="bg-full">
                <div className="bar-top-fixed">

                    <span className="ico-back" onClick={() => { navigate('/private/user') }}></span>

                </div>
                <form action="" onSubmit={submitForm} >
                    <div className="container-bienvenida">
                        <div className="form-login">
                            <h3 className="title-txt-login">Cambiar contraseña</h3>
                            <div className="form-group">
                                <input
                                    type={toggle.tog1?"text":"password"}
                                    className="form-control"
                                    placeholder="Nueva contraseña"
                                    required
                                    onChange={(e) => { setFormData({ ...formData, clave: e.target.value }) }} value={formData.clave}
                                />
								<button
                                    className="btn-show-pass"
                                    type="button"
                                    onClick={()=>{setToggle({...toggle,tog1:!toggle.tog1})}}
                                >
                                    {toggle.tog1? (
                                        <i className="fa-light fa-eye"></i>
                                    ) : (
                                        <i className="fa-light fa-eye-slash"></i>
                                    )}
                                </button>
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control w-100"
                                    type={toggle.tog2?"text":"password"}
                                    placeholder="Confirmar contraseña"
                                    onChange={(e) => { setFormData({ ...formData, clave2: e.target.value }) }} value={formData.clave2}
                                    required
                                />
                                <button
                                    className="btn-show-pass"
                                    type="button"
                                    onClick={()=>{setToggle({...toggle,tog2:!toggle.tog2})}}
                                >
                                    {toggle.tog2? (
                                        <i className="fa-light fa-eye"></i>
                                    ) : (
                                        <i className="fa-light fa-eye-slash"></i>
                                    )}
                                </button>
                            </div>
                        </div>
                        <br />

                        {
                            !wait ? (
                                <div className="text-center">
                                    <button className="btn btn-registro"  >Cambiar contraseña</button>
                                </div>
                            ) : (
                                <div className="text-center">
                                    <Loading />
                                </div>
                            )
                        }

                        <br />
                        <div className="text-center"></div>
                        
                    </div>
                </form>
			</div>
			<ToastContainer />
	</>);
}