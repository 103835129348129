import React from "react";
import { Routes, Route } from "react-router-dom";
import Bienvenida from "../components/bienvenida/bienvenida";
import Categorias from "../components/categorias/categorias";
import SubCategorias from "../components/categorias/subcategorias";
import Decretos from "../components/categorias/decretos";
import DecretosPdf from "../components/categorias/decretopdf";
import User from "../components/user/user";
import Directorio from "../components/directorio/directorio";
import Buscar from "../components/buscar/buscar";
import MostrarPDF from "../components/buscar/mostrarpdf";
import Premium from "../components/premium/premium";
import Cambiarpass from "../components/cambiarpass/cambiarpass";
import Favoritos from "../components/favoritos/favoritos";
import AdminUsuarios from "../components/adminUsuarios/adminUsuarios";
import Adduser from "../components/addUser/addUser";
import SuperAdminUsuarios from "../components/superAdmin/superAdminUsuarios";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Bienvenida />} />
        <Route path="/index.html" element={<Bienvenida />} />
        <Route path="/categoria" element={<Categorias />} />
        <Route path="/subcategoria/:idCategoria" element={<SubCategorias />} />
        <Route path="/decretos/:idCategoria" element={<Decretos />} />
        <Route path="/decretospdf/:idCategoria" element={<DecretosPdf />} />
        <Route path="/user" element={<User />} />
        <Route path="/directorio" element={<Directorio />} />
        <Route path="/buscar" element={<Buscar />} />
        <Route path="/mostrarpdf/:pdfLink" element={<MostrarPDF />} />
        <Route path="/premium/:id" element={<Premium/>} />
        <Route path="/premium" element={<Premium/>} />
        <Route path="/cambiopass" element={<Cambiarpass/>} />
        <Route path="/favoritos" element={<Favoritos/>} />
        <Route path="/adminusuarios" element={<AdminUsuarios/>} />
        <Route path="/adminusuarios/registro" element={<Adduser isChild={true}/>} />

        <Route path="/superadmin" element={<SuperAdminUsuarios />} />

        <Route path="*" element={<h1>404: Not Found</h1>} />
      </Routes>
    </>
  );
};

export default AppRoutes;
