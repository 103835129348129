// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "p.text-bienvenida {\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  text-align: center;\n  color: #4d4d4d;\n}\n\n.btn-ingresar {\n  border: 1px solid #005ba4;\n  border-radius: 10px;\n  width: 100%;\n  padding: 0.5rem 0;\n  font-size: 18px;\n  font-weight: 700;\n  color: #005ba4;\n  font-family: \"Poppins\", sans-serif !important;\n  display: block;\n  text-decoration: none;\n}\n\n.logo-serfinsa {\n  position: absolute;\n  bottom: 8%;\n  right: 3.3rem;\n  z-index: 100;\n}\nimg.serfinsa-img {\n  width: 10rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/bienvenida/assets/bienvenida.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,6CAA6C;EAC7C,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,YAAY;AACd;AACA;EACE,YAAY;AACd","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap\");\n\np.text-bienvenida {\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  text-align: center;\n  color: #4d4d4d;\n}\n\n.btn-ingresar {\n  border: 1px solid #005ba4;\n  border-radius: 10px;\n  width: 100%;\n  padding: 0.5rem 0;\n  font-size: 18px;\n  font-weight: 700;\n  color: #005ba4;\n  font-family: \"Poppins\", sans-serif !important;\n  display: block;\n  text-decoration: none;\n}\n\n.logo-serfinsa {\n  position: absolute;\n  bottom: 8%;\n  right: 3.3rem;\n  z-index: 100;\n}\nimg.serfinsa-img {\n  width: 10rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
