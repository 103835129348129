import React, { useEffect, useState } from "react";
import categoriesServices from "../../services/categories";
import { Oval } from "react-loader-spinner";
import { useParams, useNavigate } from "react-router-dom";
import { useNameCategory, useNameSubCategory } from "../../store";
import BuscadorInterno from "../buscar/buscador-interno";

export default function SubCategorias() {
  const [loading, setLoading] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const { idCategoria } = useParams();
  const categoryName = useNameCategory((state) => state.categoryName);
  const setSubCategoryName = useNameSubCategory(
    (state) => state.setSubCategoryName
  );
  const navigate = useNavigate();

  useEffect(() => {
    const getSubCategories = async () => {
      setLoading(true);
      const response = await categoriesServices.getCategoriesByParent(
        idCategoria
      );
      setSubCategories(response.data);
      setLoading(false);
    };
    getSubCategories();
  }, [idCategoria]);

  return (
    <>
      <img src='https://raw.githubusercontent.com/KevinArce/Extras/main/img/fondo.svg' alt="logo" className="fondo-custom" style={{zIndex:0}} />
      <div className="bg-full h100vh custom-houdini">
        <div className="container-custom">
          <div className="row">
            <div className="col-10">
              <p
              className="container-back"
              onClick={() => {
                navigate(-1);
              }}
              >
                <i className="fa-sharp fa-solid fa-chevron-left"></i>
                <h3 className="name-custom">{categoryName}</h3>
              </p>
            </div>
            <div className="col-2">
            <BuscadorInterno idCategoria={idCategoria} />
            </div>
          </div>
          
          

          <div className="">
            <div className="container-cards">
              <div className="card-fila">
                <div className="container-cards1 mt-2">
                  <div className="row">
                    {loading ? (
                      <div className="col-12 mt-25">
                        <Oval
                          type="ThreeDots"
                          color="#00BFFF"
                          height={80}
                          width={80}
                          strokeWidth={3}
                        />
                      </div>
                    ) : (
                      subCategories.map((subcategorias, index) => (
                        <div className="col-6" key={index}>
                          <div className="card7 cursor-pointer"  onClick={() => {
                                  if (subcategorias.has_subcategories) {
                                    setSubCategoryName(subcategorias.name);
                                    navigate(
                                      `/private/subcategoria/${subcategorias.id}`
                                    );
                                  } else {
                                    navigate(
                                      `/private/decretos/${subcategorias.id}`
                                    );
                                  }
                                }}>
                            <img
                              className="imagen imagen-category"
                              src={subcategorias.image}
                              alt="logo"
                            />
                            <div className="container-txtCard2">
                              <p
                                className="txt-custom01"
                               
                              >
                                {subcategorias.name}
                                <div className="container-iconCategories">
                                  <i className="fa-sharp fa-solid fa-circle-chevron-right"></i>
                                </div>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
