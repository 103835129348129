import axios from "axios";
import {getSession} from "./AuthContext.js";
//const urlBase = "https://164.92.146.62";
const urlBase = "https://bluedot.legal/api";
//const urlBase = "http://localhost/bluedotapi_laravel/api";
//const urlBaseServicios = "http://localhost/bluedotservices/index.php/";
//categories?filter=%5B%7B%22method%22%3A%22where%22%2C%22parameters%22%3A%5B%22category_id%22%2C6%5D%7D%5D
/**
 * @param {string}  url url a la cual consultar
 * esta funcion detecta si es una nueva url base (comienza con http:// o https://).
 * en caso de ser asi, retorna la url. en caso contrario, se asume que es un fragmento
 * de path por lo que se concatena con la constante urlBase
 **/
const readUrl = (url = "") =>
  url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `${urlBase}/${url}`;

    const get = (url = "",params={}, headers = {}) =>{
      if(getSession()){
        headers.Authorization = "Bearer "+getSession().token;
      }
      return axios.get(readUrl(url), {
        params:{...params},
        headers: {
      
          ...headers,
        },
      }
    
      );
    }
    const post = (url = "", body = {}, headers = {}) =>{
      if(getSession()){
        headers.Authorization = "Bearer "+getSession().token;
      }
      return axios.post(readUrl(url), body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
      });
    }
// const post = (url = "", body = {}, headers = {}) =>
//   axios.post(readUrl(url), body, {
//    /* headers: {
//       Accept: "application/json",
//       //      "Content-Type": "multipart/form-data",
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "access-control-allow-credentials": "true",
//       "access-control-allow-methods": "*",
//       ...headers,
//     },*/
//   });

const postUpload = (url = "", body = {}, headers = {}) =>
  axios.post(readUrl(url), body, {
   /* headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      //"Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "access-control-allow-credentials": "true",
      "access-control-allow-methods": "*",
      ...headers,
    },*/
  });

const put = (url = "", body = {}, headers = {}) =>
  axios.put(readUrl(url), body, {
   /* headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "access-control-allow-credentials": "true",
      "access-control-allow-methods": "*",

      ...headers,
    },*/
  });

const del = (url = "", headers = {}) =>
  axios.delete(readUrl(url), {
   /* headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "access-control-allow-credentials": "true",
      "access-control-allow-methods": "*",

      ...headers,
    },*/
  });

const exportObj = {
  get,
  post,
  put,
  postUpload,
  delete: del,
};

export default exportObj;
