//import React,{useEffect,useState} from "react";

const Header = (props) => {
  return (
    <>
     {/*  <h1></h1> */}
    </>
  );
};

export default Header;
