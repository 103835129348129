import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import axios from 'axios';
import "./assets/buscar.css";
import { Link } from 'react-router-dom';
import { getSession } from "../../services/AuthContext";
import servicesApi from "../../services/services";
import MostrarPDF from "./mostrarpdf";

const Buscar = () => {
  const [loading, setLoading] = useState(false);
  const [lawsAndPhones, setLawsAndPhones] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [filterType, setFilterType] = useState(0);
  const [showError, setShowError] = useState(false);
  const [idCategoria, setIdCategoria] = useState(null);
  const navigate = useNavigate();
  const  user_sess  = getSession();

  const [pdfMode,setPdfMode] = useState(false);
  const [currentPDFLink,setCurrentPDFLink] = useState(null);
  const [itemPDF,setItemPDF] = useState(null);

  const [searchParams] = useSearchParams();
  
  useEffect(()=>{
    if(!user_sess.isPremium){
      navigate("/private/User");
    }
    checkParams();
  },[])
  
  function checkParams(){
    if(searchParams.get("searchValue")!=null && searchParams.get("searchValue")!=""){
      setSearchValue(searchParams.get("searchValue"));
    }
    if(searchParams.get("filterType")!=null && searchParams.get("filterType")!=""){
      setFilterType(Number(searchParams.get("filterType")));
    }
    if(searchParams.get("idCategoria")!=null && searchParams.get("idCategoria")!=""){
      setIdCategoria(searchParams.get("idCategoria"));
    }
    if(searchParams.get("autoSearch")=="true"){
      setSearchPerformed(true);
      //fetchData();
    }
    //console.log(searchParams.get("idCategoria"));
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      servicesApi.useService(true);

      const response = await servicesApi.post("/search",{ pwdkey: "M4rd0queo1", limit: "5", search: searchValue, type: filterType,idCategoria });

      /* const response = await axios.post(
        'https://bluedot.legal/apinew/bluedotservices/index.php/api/search',
        { pwdkey: "M4rd0queo1", limit: "5", search: searchValue, type: filterType,idCategoria }
      ); */
      const data = response.data;
      if (data.error === 0) {
        setLawsAndPhones(data.data.leyes);
      } else {
        setLawsAndPhones([]);
      }
      setSearchPerformed(false);
    } catch (error) {
      console.error('Error fetching laws and phones:', error);
      setLawsAndPhones(null);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (searchPerformed) {
      fetchData();
    }
  }, [searchValue, searchPerformed, filterType]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchSubmit = () => {
    if (filterType !== 0) {
      setSearchPerformed(true);

      setShowError(false);
    } else {
      setShowError(true);
    }
  };


  const handleFilterChange = (event, type) => {
    setFilterType(type);
  };

  const formatContent = (content) => {
    return content.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };
  const handleViewPDF = (pdfLink) => {
    // Aquí puedes implementar la lógica de navegación a la pantalla de visualización del PDF
    // Puedes usar una función de enrutamiento o cualquier otra forma de navegación que estés utilizando en tu aplicación
    navigate(`/ruta-al-pdf/${encodeURIComponent(pdfLink)}`);
  }

  function visorPDF(item){
    let _link = (item.source_path)
      setCurrentPDFLink(_link);
      setItemPDF(item);
      //console.log(_link);
      setPdfMode(true);
  }

  return (
    <>
      <img src='https://raw.githubusercontent.com/KevinArce/Extras/main/img/fondo.svg' alt="logo" className="fondo-custom" />

      <div className="contenido-full">
        <div className="container-custom">
          {
            idCategoria !=null && (
          <div style={{marginTop:-25}}>
              <span className="ico-back" style={{marginTop:-10}} onClick={()=>{window.history.back()}} ></span>
          </div>
          )
        }
          <p className="container-name">
            <h3 className="title">Buscar</h3>
          </p>

          <div className="container-buscar2">
            <input
              className="input-buscar-new"
              type="text"
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Buscar..."
            />
            <button className="button-buscar" onClick={handleSearchSubmit}>Buscar</button>

            <div className="container-filters">
              <p className="txt-buscar-por">Buscar por:</p>
              <div className="card-filter">
                <label className="label-custom" htmlFor="nameFilter">Nombre de ley:</label>
                <div className="form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="nameFilter"
                    checked={filterType === 2}
                    onChange={(event) => handleFilterChange(event, 2)}
                  />
                </div>
              </div>

              <div className="card-filter d-none">
                <label className="label-custom" htmlFor="contentFilter">Contenido:</label>
                <div className="form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="contentFilter"
                    checked={filterType === 3}
                    onChange={(event) => handleFilterChange(event, 3)}
                  />
                </div>
              </div>

              <div className="card-filter">
                <label className="label-custom" htmlFor="keywordFilter">Palabra clave:</label>
                <div className="form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="keywordFilter"
                    checked={filterType === 1}
                    onChange={(event) => handleFilterChange(event, 1)}
                  />
                </div>
              </div>
            </div>
          </div>

          {showError && (
            <div className="error-message">
              Por favor, seleccione un filtro antes de realizar la búsqueda.
            </div>
          )}

          <div className="container-section-cards">
            <div className="cons">
              {loading ? (
                <div className="col-12 mt-25">
                  <Oval
                    className="loading"
                    type="ThreeDots"
                    color="#00BFFF"
                    height={80}
                    width={80}
                    strokeWidth={3}
                  />
                </div>
              ) : (
                lawsAndPhones.map((item) => (
                  <div className={`container-decreto ${selectedCard === item.id ? "selected" : ""}`} key={item.id}>

                    <div className="linkcustom" onClick={()=>{visorPDF(item)}}>
                      {/* <Link to={`/private/mostrarpdf/${encodeURIComponent(item.pdfLink)}`} className="view-pdf-button">
                        <h4 className="txt-title-name">{item.name}</h4>
                      </Link> */}
                      <h4 className="txt-title-name">{item.name}</h4>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      {
        pdfMode && (
          <MostrarPDF pdfLink={currentPDFLink} visorMode={true} close={()=>{setPdfMode(false)}} item={itemPDF} />
        )
      }
    </>
  );

};

export default Buscar;