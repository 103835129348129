import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import $ from 'jquery';
import { pdfjs,Document, Page } from "react-pdf";
import servicesApi from "../../services/services";

/* pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString(); */
  
 

 

const MostrarPDF = (props) => {
    //const {pdfLink}  = useParams();
    const [pdfLink, setPdfLink] = useState(useParams.pdfLink);
    const [itemPDF,setItemPDF] = useState({});

    const canvasRef = useRef(null);

    useEffect(() => {
     
        if (props.pdfLink != null) {
             
            //let _link = props.pdfLink;
            //let _link = linkk;
            setPdfLink(props.pdfLink);
            //preparePDF(_link);
            setItemPDF(props.item);
      }
    }, [props.pdfLink,props.item]);


    function cerrar_visor() {
        props.close();
    }



    const navigate = useNavigate();

    async function preparePDF(pdfLink) {
        /* const pdfJS = await import('pdfjs-dist/build/pdf');
        pdfJS.GlobalWorkerOptions.workerSrc =
            window.location.origin + '/pdf.worker.min.js'; */


        //const bufferpdf = await getPDFRemote(pdfLink);
        //const pdf = await pdfJS.getDocument(pdfLink).promise;
        /*  var _link = "http://192.168.1.117/bluedotservices/index.php/api/getbufferpdf?pdf=http://192.168.1.117/pdf-test.pdf";
         const pdf = await pdfJS.getDocument(_link).promise;
   
               const page = await pdf.getPage(1);
               const viewport = page.getViewport({ scale: 1});
   
               // Prepare canvas using PDF page dimensions.
               const canvas = canvasRef.current;
               const canvasContext = canvas.getContext('2d');
               canvas.height = viewport.height;
               canvas.width = viewport.width;
   
               // Render PDF page into canvas context.
               const renderContext = { canvasContext, viewport };
               page.render(renderContext); */
    }
 
    function setFavorito(){
        servicesApi.useService(true);
        servicesApi.post("guardar_favorito",{id_law:itemPDF.id}).then((response)=>{
            let favs = response.data.favoritos;
            let _itemPDF = {...itemPDF};
            let _id = ''+itemPDF.id;
            _itemPDF.favorito = favs.includes(_id) ? 1 : 0 ;
            console.log(_itemPDF)
            setItemPDF(_itemPDF);
        });
    }


    return (
        <div className={`bg-full h100vh custom-houdini ${props.visorMode && `visor-mode`}`}>
            <div className="container-custom-pdf">
              {/*   <p className="txt-custom03"
                    onClick={() => { cerrar_visor() }}
                >
                    <i className="fa-sharp fa-solid fa-chevron-left back-pdf"></i>
                </p> */}
                <div style={{marginTop:-25}}>
                    <div className="row  ">
                        <div className="col-6 jus-cont-start">
                            <span className="ico-back" style={{marginLeft:25}} onClick={()=>{ cerrar_visor() }} ></span>
                        </div>
                        <div className="col-6 jus-cont-end">
                            <i className={`fa-sharp fa-solid fa-bookmark ${itemPDF.favorito==1?'checked':''}`}  style={{paddingRight:30}} onClick={()=>{setFavorito()}}></i>
                        </div>
                    </div>
                </div>
                <div className="container-pdf">
                    {/* <iframe
                        src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfLink)}&embedded=true&rm=minimal`}
                        url={pdfLink}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        scrolling="no"
                    ></iframe> */}
                     <iframe
                        src={"https://bluedot.legal/visor_pdf/web/viewer.html?file="+encodeURIComponent(pdfLink)}
                        url={pdfLink}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        scrolling="no"
                    ></iframe>
                    {/*  <object data={(pdfLink)}
                width="800"
                height="500"> 
        </object>  */}
                    {/*   <canvas ref={canvasRef} style={{ height: '100vh' }} /> */}
                    {/* <div className="disable-external-open"> </div> */}
                   {/*  <Document file={(pdfLink)} renderMode="false" renderTextLayer={false}>
                        <Page pageNumber={1}  />
                    </Document> */}
                </div>

            </div>
        </div>
    );
};

export default MostrarPDF;
