import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaCreditCard } from "react-icons/fa";
import categoriesServices from "../../services/categories";
import fondo from "../../globalassets/img/fondo.svg";
import logo from "../../globalassets/img/logo192.png";
import { setSession, getSession } from "../../services/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import "./assets/premium.css";
import ModalSuccess from "../ModalConfirm/ModalSuccess";

import servicesApi from "../../services/services";
import Loading from "../layout/Loading";

import Tarjetas from "../tarjetas/tarjetas";
import ModalConfirm from "../ModalConfirm/ModalConfirm";

export default function Premium() {
    const [showModal, setShowModal] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [tarifa, setTarifa] = useState({});
    const [tarifas, setTarifas] = useState([]);
    // Inside your Premium component
    const [errorMessage, setErrorMessage] = useState("");


    const navigate = useNavigate();
    const { id } = useParams();

    const [idToken, setIdToken] = useState(0);
    const [wait, setWait] = useState(false);
    const [errorDesc, setErrorDesc] = useState('');
    const [showConfirmar,setShowConfirmar] = useState(false);

    //VER TARIFA DATA

    function getTarifas() {
        servicesApi.useService(true);
        servicesApi.get("get_tarifas").then((response) => {
            setTarifas(response.data.tarifas);
            setTarifa(response.data.tarifas[0])
        });
    }

    useEffect(() => {
        /* const getTarifa = async () => {
            const response = await categoriesServices.getTarifa();
            setTarifa(response.data.parameters);
        };

        getTarifa(); */
        getTarifas();
    }, []);

    function back_user() {
        setShowModal(false);
        navigate('/private/user');
    }
    //END TARIFA DATA


    function pagar() {
        let dataPost = {
            id_token: idToken,
            id_tarifa: tarifa.id
        }

        // add id_user to dataPost if exists
        if (id) {
            dataPost.id_user_tercero = id;
        }

        setWait(true);
        setErrorDesc('');

        servicesApi.useService(true);
        servicesApi.post("payments/process_pay", dataPost).then((response) => {
            if (response.data.error != 0) {
                setErrorDesc(response.data.error);
            } else {
                let s = getSession();

                setSession({ ...s, isPremium: true });

                setShowModal(true);
                setIdToken(0);

            }
            setWait(false);
        })
    }



    return (
        <>
            <img
                src={fondo}
                alt="logo"
                className="fondo-custom"
            />
            <div className="bg-full h100vh custom-houdini">
                <div className="container-custom2">
                <div className="d-flex align-items-center" style={{marginBottom:-30}}>
                    <span
                        className="ico-back"
                        onClick={() => navigate(-1)}
                    ></span>
                </div>
                    <div className="text-center">
                        <h1 className="title-suscription">
                            SUSCRIPCIÓN
                            <br />
                            Blue dot
                        </h1>
                        <div className="instrucciones">
                            <h4>Instrucciones</h4>
                            <ol>
                                <li>Seleccione el tipo de suscripción (mensual o anual)</li>
                                <li>Registre una tarjeta de crédito o débito. Si ya tiene una tarjeta registrada en el sistema, omita este paso.</li>
                                <li>Seleccione una tarjeta registrada</li>
                                <li>Presione el botón de "Pagar"</li>
                            </ol>
                        </div>
                        {
                            tarifas.map((tar,index) => (
                                <div className={`card-custom-price ${tar.id == tarifa.id && 'selected'}`} onClick={()=>{setTarifa(tar)}} key={index}>
                                    <div className="column1">
                                        <img
                                            src="https://raw.githubusercontent.com/KevinArce/Extras/main/img/logo192.png"
                                            alt="logo"
                                            className="logo-custom"
                                        />
                                        <div className="subsection">
                                            <h3 className="txt-suscription">{tar.nombre_suscripcion}</h3>
                                            <p className="txt-premium">{tar.descripcion_suscripcion}</p>
                                        </div>
                                    </div>
                                    <div className="column2">
                                        <p className="txt-price">${Number(tar.precio_suscripcion).toFixed(2)}</p>
                                    </div>
                                </div>
                            ))
                        }


                        <div className="panel mb-3">
                            <label htmlFor="" className="panel-title">Seleccione una tarjeta registrada</label>
                            <Tarjetas seleccionMode="true" getIdTokenCard={setIdToken} />
                        </div>
                        <hr />
                        {
                            errorDesc != '' && (
                                <div className="text-center mb-2">
                                    <label htmlFor="" className="lbl-error">{errorDesc}</label>
                                </div>
                            )
                        }

                        {
                            !wait ? (
                                <button className="btn-pagar" onClick={()=>{setShowConfirmar(true)}} disabled={idToken == 0}>
                                    Pagar
                                </button>
                            ) : (<Loading />)
                        }





                    </div>
                </div>
            </div>
            <ModalSuccess show={showModal} onHide={() => { back_user(); }} text="Pago realizado con éxito" />
            
            <ModalConfirm 
				text={`Pagar suscripción por $${tarifa.precio_suscripcion}`}
				show={showConfirmar}
				onHide={()=>{setShowConfirmar(false)}}
				onConfirm={()=>pagar()}
			/>
        </>
    );
}
