import React, { useState, useEffect } from "react";
import $ from 'jquery';
import icoSearch from "../../globalassets/img/ico-search.svg";
import { Modal, Button } from "react-bootstrap";
import { useNavigate ,createSearchParams} from "react-router-dom";

import { Link } from 'react-router-dom';
import { getSession } from "../../services/AuthContext";

export default function BuscadorInterno(props) {

	const [openBuscador, setOpenBuscador] = useState(false);

	function abrirModalBuscador() {
		setOpenBuscador(true)
	}
	function onHide() {
		setOpenBuscador(false);
	}

	const [loading, setLoading] = useState(false);
	const [lawsAndPhones, setLawsAndPhones] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchPerformed, setSearchPerformed] = useState(false);
	const [selectedCard, setSelectedCard] = useState(null);
	const [filterType, setFilterType] = useState(0);
	const [showError, setShowError] = useState(false);
	const navigate = useNavigate();
	const  user_sess  = getSession();

	const handleSearchChange = (event) => {
		setSearchValue(event.target.value);
	  };
	
	  const handleSearchSubmit = () => {
		/* if (filterType !== 0) {
		  setSearchPerformed(true);
	
		  setShowError(false);
		} else {
		  setShowError(true);
		} */
		let filters = {
			searchValue,
			filterType,
			idCategoria:props.idCategoria,
			autoSearch:true
		}
		navigate({
			pathname: "/private/buscar",
			search: createSearchParams(filters).toString()
		});
		
	  };
	
	
	  const handleFilterChange = (event, type) => {
		setFilterType(type);
	  };

	return (
		<>
			<div className="text-right">
				<span className="button-search cursor-pointer" onClick={abrirModalBuscador}>
					<img src={icoSearch} height={25} alt="" />
				</span>
			</div>
			<Modal className="modal-buscador" show={openBuscador} onHide={onHide} >

				<Modal.Header closeButton>

				</Modal.Header>
				<Modal.Body closeButton>
					<input type="text" className="input-buscador"
					value={searchValue}
					onChange={handleSearchChange}
					placeholder="Buscar..."
					 />
					<div className="container-buscar2">
						 
						

						<div className="container-filters">
							<p className="txt-buscar-por">Buscar por:</p>
							<div className="card-filter">
								<label className="label-custom" htmlFor="nameFilter">Nombre de ley:</label>
								<div className="form-switch">
									<input
										className="form-check-input"
										type="checkbox"
										role="switch"
										id="nameFilter"
										checked={filterType === 2}
										onChange={(event) => handleFilterChange(event, 2)}
									/>
								</div>
							</div>

						{/* 	<div className="card-filter">
								<label className="label-custom" htmlFor="contentFilter">Contenido:</label>
								<div className="form-switch">
									<input
										className="form-check-input"
										type="checkbox"
										role="switch"
										id="contentFilter"
										checked={filterType === 3}
										onChange={(event) => handleFilterChange(event, 3)}
									/>
								</div>
							</div> */}

							<div className="card-filter">
								<label className="label-custom" htmlFor="keywordFilter">Palabra clave:</label>
								<div className="form-switch">
									<input
										className="form-check-input"
										type="checkbox"
										role="switch"
										id="keywordFilter"
										checked={filterType === 1}
										onChange={(event) => handleFilterChange(event, 1)}
									/>
								</div>
							</div>
							<button className="button-buscar" onClick={handleSearchSubmit}>Buscar</button>
						</div>
					</div>

					 
				</Modal.Body>


			</Modal>

		</>);
}