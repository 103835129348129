import api from "./api";
import axios from 'axios';

const getDirectorio = () => {

  const url = `https://bluedot.legal/api/phones`;
  const response = api.get(encodeURI(url));

  return response;
};

const getTarifa = () => {

  const url = `https://bluedot.legal/apinew/bluedotservices/index.php/api/ver_tarifa`;
  const response = api.get(encodeURI(url));

  return response;
};

const getCategories = () => {
  //http://bluedot.legal/api/categories?filter=%5B%7B%22method%22%3A%22where%22%2C%22parameters%22%3A%5B%22category_id%22%2Cnull%5D%7D%5D\
  const url = `categories?filter=[{"method":"where","parameters":["category_id",null]}]`;
  const response = api.get(encodeURI(url));

  return response;
};

const getCategoriesByParent = (parentId) => {
  const url = `categories?filter=[{"method":"where","parameters":["category_id",${parentId}]}]`;
  const response = api.get(encodeURI(url));
  //convert to a url friendly string

  return response;
};

const getPdfCategoriesByParent = (parentId) => {
  const url = `laws?filter=[{"method":"where","parameters":["category_id",${parentId}]}]`;
  const response = api.get(encodeURI(url));

  return response;
};

const getPdfCategoryById = (id) => {
  const url = `laws?filter=[{"method":"where","parameters":["category_id",${id}]}]`;
  const response = api.get(encodeURI(url));

  return response;
};

const getCategoriesById = (id) => {
  const url = `categories?filter=[{"method":"where","parameters":["id",${id}]}]`;
  const response = api.get(encodeURI(url));
  //convert to a url friendly string

  return response;
};

const handleSearch = async (search) => {
  try {
    const response = await axios.post('http://bluedot.legal/api/search', {
      category: '1',
      text: search,
      type: 'law'
    });
    console.log(response.data);
    // Process the response data as needed
  } catch (error) {
    console.error(error);
    // Handle the error
  }
};

export default {
  getCategories,
  getCategoriesByParent,
  getCategoriesById,
  getPdfCategoriesByParent,
  getPdfCategoryById,
  getDirectorio,
  handleSearch,
  getTarifa
};
