import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FacebookLogin from "react-facebook-login";

const Facebook = () => {
  let navigate = useNavigate();
  const [details, setDetails] = useState({
    isLoggedIn: false,
    userID: "",
    name: "",
    email: "",
    picture: "",
  });

  const responseFacebook = (response) => {
    setDetails({
      isLoggedIn: true,
      userID: response.userID,
      name: response.name,
      email: response.email,
      picture: response.picture.data.url,
      accessToken: response.accessToken,
    });

    if (response.accessToken) {
      navigate("/private/categoria");
    } else {
      navigate("/");
    }
  };

  const componentClicked = () => {
    console.log("clicked");
  };

  return details.isLoggedIn ? (
    <div>test</div>
  ) : (
    <FacebookLogin
      appId="1081986449858993"
      autoLoad={false}
      fields="name,email,picture"
      onClick={componentClicked}
      callback={responseFacebook}
      cssClass="my-facebook-button-class"
      icon="fa-facebook"
      textButton="Iniciar sesión con Facebook"
    />
  );
};

export default Facebook;