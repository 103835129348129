import React from "react";
import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Login from "../components/login/login";
import Registro from "../components/registro/registro";
import Bienvenida from "../components/bienvenida/bienvenida";
import AppRoutes from "../routers/AppRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "../globalassets/css/global-styles.css";
import SendEmail from "../components/SendEmail/SendEmail";
import { getSession } from "../services/AuthContext";

const App = () => {
  const  user  = getSession();
 
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Bienvenida />} />
        <Route exact path="/index.html" element={<Bienvenida />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/registro" element={<Registro />} />
        <Route exact path="/request-mail" element={<SendEmail />} />
        <Route
          path="/private/*"
          element={
            <PrivateRoute user={user}>
              <AppRoutes />
            </PrivateRoute>
          }
        />
      </Routes>
    </HashRouter>
  );
};

export default App;
