import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";


export default function ModalConfirm(props){
	const [showModal, setShowModal] = useState(false);
	const handleModalToggle = () => {
		setShowModal(!showModal);
	};
	function callback(){
		props.onHide();
		if(typeof props.onConfirm == 'function'){
			props.onConfirm(true);
		}
	}
	return (
		<Modal show={props.show} onHide={props.onHide} centered>
				
					<Modal.Header closeButton>
						
					</Modal.Header>
					<Modal.Body>
						<div className="text-center mb-4">
							<h4 htmlFor="">{props.text}</h4>
						</div>
						<div className="row">
							<div className="col-6">
								<Button variant="outline-secondary" onClick={()=>{props.onHide()}}>Cancelar</Button>
							</div>
							<div className="col-6">
								<Button onClick={callback}>Confirmar</Button>
							</div>
						</div>
					</Modal.Body>
					
				
			</Modal>
	)
}