import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import servicesApi from "../../services/services";
import fondo from "../../globalassets/img/fondo.svg";
import "./adminUsuarios.css";
import axios from "axios";
import { FiTrash2 } from "react-icons/fi";
import { FaDollarSign } from "react-icons/fa";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import ModalSuccess from "../ModalConfirm/ModalSuccess";

export default function AdminUsuarios() {
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [descCancelError, setDescCancelError] = useState("");
  const [showModalSuccessCancel, setShowModalSuccessCancel] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [userSelected, setUserSelected] = useState(0);
  const [id, setId] = useState(0);
  const [usuarios, setUsuarios] = useState([]);
  const navigate = useNavigate();
  function check_user_status() {
    servicesApi.useService(true);
    servicesApi.get("payments/check_user_status").then((response) => {
      setId(response.data.data.id_user);
    });
  }

  function getUsersofParent(){
    servicesApi.useService(true);
    servicesApi.get("listar_usuarios").then((response) => {
      setUsuarios(response.data.usuarios);
    });
  }
 
  useEffect(() => {
    check_user_status();
    getUsersofParent();
  }, []);

  function cancelSubscription() {
    setLoadingCancel(true);
    setDescCancelError("");
    servicesApi.useService(true);
    servicesApi.get("payments/cancel_subscription?user_tercero=" + userSelected).then(
      (response) => {
        if (response.data.error == 0) {
          setShowModalSuccessCancel(true);
          check_user_status();
          setUserSelected(0);
        } else {
          setDescCancelError(response.data.message);
          setUserSelected(0);
        }

        setLoadingCancel(false);
        setUserSelected(0);
      },
      () => {
        setDescCancelError("Error, intente más tarde");
        setLoadingCancel(false);
        setUserSelected(0);
      }
    );
  }
  return (
    <div className="contenedor">
      <header className="w-100 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <span
            className="ico-back"
            onClick={() => navigate("/private/user")}
          ></span>
        </div>
        <h2 className="fw-bold">Mis usuarios</h2>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => navigate("/private/adminusuarios/registro")}
        >
          Nuevo usuario
        </button>
      </header>
      <div className="container mt-4">
        {usuarios.map((usuario, index) => (
          <div
            key={index}
            className="card mb-2"
            // onClick={() => navigate("/private/premium/" + usuario.id)}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <h3>{usuario.name}</h3>
                  <span
                    className="text-muted d-block"
                    style={{ fontSize: "12px" }}
                  >
                    {usuario.email}
                  </span>
                  {usuario.suscription.isPremium ? (
                    <span
                      className="text-muted d-block"
                      style={{ fontSize: "12px" }}
                    >
                      Activo
                    </span>
                  ) : (
                    <span
                      className="text-muted d-block"
                      style={{ fontSize: "12px" }}
                    >
                      Inactivo
                    </span>
                  )}
                </div>
                <div className="col-5 d-flex flex-column justify-content-center align-items-center">
                  <div className="dropdown">
                    {!usuario.suscription.isPremium || usuario.status_subscription=="2" ? (
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/private/premium/" + usuario.id);
                        }}
                      >
                        Pagar
                      </button>
                    ) : (
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => {
                          setShowModalConfirm(true);
                          setUserSelected(usuario.id);
                        }}
                      >
                        Cancelar
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {
          usuarios.length == 0 && (
            <div className="sin-usuarios"><h4>No tiene usuarios registrados</h4></div>
          )
        }
      </div>
      <img src={fondo} alt="logo" className="fondo-custom" />
      <ModalConfirm
        show={showModalConfirm}
        onHide={() => {
          setShowModalConfirm(false);
          setUserSelected(0);
        }}
        text="¿Está seguro de cancelar su suscripción?"
        onConfirm={cancelSubscription}
      />
      <ModalSuccess
        show={showModalSuccessCancel}
        onHide={() => {
          setShowModalSuccessCancel(false);
          setUserSelected(0);
        }}
        text="Suscripción cancelada con éxito"
      />
    </div>
  );
}
