import React, { useEffect } from "react";
import logo from "../../globalassets/img/logo.svg";
import fondo from "../../globalassets/img/fondo.svg";
import { NavLink } from "react-router-dom";
import "./assets/bienvenida.css";
import { setSession, getSession } from "../../services/AuthContext";
import servicesApi from "../../services/services";
import { useNavigate } from "react-router-dom";

export default function Bienvenida() {
  const navigate = useNavigate();
  useEffect(() => {
    check_user_status();


  }, []);

  function check_user_status() {
    servicesApi.useService(true);
    servicesApi.get("payments/check_user_status").then((r) => {
      console.log(r)
      if (r.data && r.data.error == 0 && r.data.data.email) {
        let isPremium = r.data.data.isPremium;
        let d = getSession();
        d.isPremium = isPremium;
        setSession(d);
        navigate('/private/categoria');
      }
    })

  }


  return (
    <>
      <img src={fondo} alt="logo" className="fondo-custom" />
      <div className="bg-full h100vh">
        <div className="container-background"></div>
        <div className="container-bienvenida">
          <div className="text-center">
            <div className="login-logo">
              <h1 className="title-custom">BLUE DOT</h1>
              <img src={logo} alt="logo" />
            </div>
          </div>

          <div className="section-bienvenida text-center">
            <div className="text-center">
              <NavLink className="btn btn-registro" to="/registro">
                Registrarse
              </NavLink>
            </div>
            <br />
            <p className="text-bienvenida"> ¿Ya tienes una cuenta? </p>
            <div className="text-center">
              <NavLink className="btn-ingresar" to="/login">
                Ingresar
              </NavLink>
            </div>
          </div>
          <div className="logo-serfinsa">
            <img
              src="https://res.cloudinary.com/djyulfuk6/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1704999606/logo-serfinsa_w54rkm.jpg?_s=public-apps"
              alt="Serfinsa Logo" className="serfinsa-img"
            />
          </div>
        </div>
      </div>
    </>
  );
}
