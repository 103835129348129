import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./success.css";

export default function ModalSuccess(props) {
	
	return (
		<Modal show={props.show} onHide={props.onHide} centered>

			<Modal.Header closeButton>

			</Modal.Header>
			<Modal.Body>
				<div className="text-center">
					<div class="success-animation">
						<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
					</div>
					<h4 className="success-text">{props.text}</h4>
				</div>
			</Modal.Body>



		</Modal>
	)
}